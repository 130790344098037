import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout, { ILayout } from 'modules/layout';
import Meta from 'modules/meta';
import Template from './Template';

export const query = graphql`
  query ProblemSolved {
    contentfulSiteConfig {
      ...siteConfig
    }
    contentfulProblemSolved {
      ...formSectionProblemSolved
      ...metaProblemSolved
    }
  }
`;

const Page: React.FunctionComponent<PageProps<Queries.ProblemSolvedQuery>> &
  ILayout = ({ data }) => {
  return <Template {...data} />;
};

Page.Layout = Layout;
export default Page;

export const Head: React.FunctionComponent<
  PageProps<Queries.ProblemSolvedQuery>
> = ({ data, location }) => {
  return (
    <Meta
      defaultMeta={data.contentfulSiteConfig?.defaultMeta}
      meta={data.contentfulProblemSolved?.meta}
      pageTitle="Problem Solved"
      pathname={location.pathname}
    />
  );
};
