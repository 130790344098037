import React from 'react';
import clsx from 'clsx';

import Chevron from 'modules/theme/images/form/chevron.svg';
import { SelectProps } from './types';
import { useHandlers, useTabIndex } from './hooks';

import './styles.scss';

export type { SelectOption } from './types';

const Select: React.FunctionComponent<SelectProps> = ({
  autoFocus,
  children,
  disabled,
  id,
  inputRef,
  invalid,
  label,
  name,
  onBlur,
  onFocus,
  options,
  placeholder,
  readOnly,
  tabIndex,
  value,
  ...props
}) => {
  const { handleBlur, handleFocus } = useHandlers(disabled, onBlur, onFocus);
  const tabindex = useTabIndex(disabled, readOnly, tabIndex);

  const className = clsx(
    'select',
    disabled && 'disabled',
    readOnly && 'read-only',
    value === '' && 'placeholder',
    !disabled && invalid && 'invalid',
  );

  return (
    <div className={className}>
      <select
        autoFocus={!disabled && autoFocus}
        disabled={disabled}
        id={id || name}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        ref={inputRef}
        tabIndex={tabindex}
        value={value || ''}
        {...props}>
        {options?.map((option, key) => (
          <option disabled={option.value === ''} key={key} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <Chevron />
      {children}
      {!!label && (
        <label className="label" htmlFor={id ? id : name}>
          {label}
        </label>
      )}
    </div>
  );
};

export default React.memo(Select);
