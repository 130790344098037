import React from 'react';
import { FieldValues } from 'react-hook-form';

import { QuestionCheckboxesProps } from '../types';
import QuestionTemplate from '..';
import Checkboxes from 'components/Inputs/Checkboxes';

function QuestionCheckboxes<T extends FieldValues>({
  disabled,
  control,
  id,
  label,
  name,
  readOnly,
  ...props
}: QuestionCheckboxesProps<T>) {
  return (
    <QuestionTemplate<T> control={control} disabled={disabled} name={name}>
      {({ invalid, onBlur, onFocus, ref }) => (
        <Checkboxes<T>
          containerRef={ref}
          disabled={disabled}
          id={id}
          invalid={invalid}
          label={label}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          readOnly={readOnly}
          {...props}
        />
      )}
    </QuestionTemplate>
  );
}

export default React.memo(QuestionCheckboxes) as typeof QuestionCheckboxes;
