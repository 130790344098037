import React from 'react';

import { Error, FormOrigin, Submitted, useForm } from 'modules/form';
import { LazyloadBackground } from 'components/Image';
import { LinkColour } from 'components/Hyperlink';
import { problemSolvedConversion } from 'modules/scripts/marketing/GoogleTagManager';
import Form, { Model } from './Form';
import PageLink from 'components/Hyperlink/PageLink';

import './styles.scss';

type ProblemSolvedFormType = {
  children: React.ReactNode;
  description?: {
    description: string | null;
  } | null;
  submittedDescription?: {
    raw: string | null;
  } | null;
  submittedTitle?: string | null;
  title?: string | null;
};

const ProblemSolvedForm: React.FunctionComponent<ProblemSolvedFormType> = ({
  children,
  description,
  submittedDescription,
  submittedTitle,
  title,
}) => {
  const {
    errorRef,
    handleSubmit,
    isError,
    isSubmitted,
    isSubmitting,
    recaptcha,
    setIsSubmitting,
    submittedRef,
  } = useForm<Model>({
    callback: problemSolvedConversion,
    formOrigin: FormOrigin.PROBLEM_SOLVED,
  });

  return isError ? (
    <React.Fragment>
      {children}
      <Error
        className="problem-solved-settled"
        forwardedRef={errorRef}
        message={isError}>
        <LazyloadBackground
          image={[{ url: '/images/problem-solved/form-background.jpeg' }]}
          local={true}
          tag="span"
        />
      </Error>
    </React.Fragment>
  ) : isSubmitted ? (
    <React.Fragment>
      {children}
      <Submitted
        className="problem-solved-settled"
        description={submittedDescription?.raw}
        forwardedRef={submittedRef}
        title={submittedTitle}>
        <PageLink colour={LinkColour.RED} label="Visit our website" slug="/" />
        <LazyloadBackground
          image={[{ url: '/images/problem-solved/form-background.jpeg' }]}
          local={true}
          tag="span"
        />
      </Submitted>
    </React.Fragment>
  ) : (
    <Form
      description={description?.description}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      recaptcha={recaptcha}
      setIsSubmitting={setIsSubmitting}
      title={title}>
      {children}
    </Form>
  );
};

export default React.memo(ProblemSolvedForm);
